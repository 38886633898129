import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import WebriQForm from "@webriq/gatsby-webriq-form"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"

// import phoneIcon from "../images/about-us/phone.svg"
// import emailIcon from "../images/about-us/mail.svg"
// import locationIcon from "../images/about-us/address.svg"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" description="Thank You Page" />
    <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>Thank you for reaching out!</h1>
            <p>
              A team member will be in touch with you shortly. <br /> You don't
              have to wait though. <br />
              Please call or text <a href="tel:303-587-2389"> 303-587-2389 </a>
              for an even faster response.
            </p>
          </div>
        </div>
        <div className="col-md-7 contact-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>Thank you for reaching out!</h1>
            <p>
              A team member will be in touch with you shortly. <br /> You don't
              have to wait though. <br />
              Please call or text <a href="tel:303-587-2389">
                {" "}
                303-587-2389{" "}
              </a>{" "}
              for an even faster response.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div> */}

    {/* <div className="container contact-wrapper">
      <div className="row align-items-center">
        <h2>Thank you for keeping in touch!</h2>
      </div>
    </div> */}

    <div className="apply-section">
      <div className="protruded-gradient"></div>
      <div className="container">
        <div className="apply-container">
          <div className="row">
            <div className="col-md-10">
              <div>
                <h2 className="mb-2 mt-3">
                  Let's get started
                  <br />
                </h2>
                <p className="pb-4">
                  Getting pre-approved can be quick and easy. Start the process
                  today!
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <Link to="https://plus.preapp1003.com/Darlene-Franklin" className="btn-global w-100 mb-3" type="button">
                Apply Now
              </Link>
              <Link
                to="/loan-process"
                className="btn-global-inverted w-100 text-center"
                type="button"
                style={{ padding: "10px 20px" }}
              >
                Our Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
